.main-footer {
  :global {
    color: #242331;
    border-radius: 100px 100px 0px 0px;
    background: #fff;
    [data-theme="dark"] & {
      background: var(--tj-body-light-color);
    }
    @media (max-width: 992px) {
      border-radius: 50px 50px 0px 0px;
    }

    .container {
      .footer-top {
        padding-top: 60px;
        padding-bottom: 60px;
        .row {
          row-gap: 25px;
        }
        .footer-head-title {
          margin-bottom: 10px;
          h3 {
            font-weight: 500;
            position: relative;
            padding-bottom: 18px;
            color: #242331;
            font-size: 20px;
            text-transform: capitalize;
            @media (max-width: 992px) {
              font-size: 18px;
            }
            @media (max-width: 576px) {
              font-size: 17px;
              padding-bottom: 16px;
            }
            [data-theme="dark"] & {
              color: #d1d0da;
            }
            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              width: 43px;
              height: 4px;
              border-radius: 8px;
              background: var(--tj-linear-gradient-light);
              [data-theme="dark"] & {
                background: var(--tj-linear-gradient-dark);
              }
            }
          }
        }
        .first-col {
          .footer-logo {
            .logo {
              display: flex;
              margin-bottom: 16px;
              @media (max-width: 992px) {
                justify-content: center;
              }
            }
          }
          .desc {
            margin-top: 25px;
            p {
              color: #494949;
              font-size: 15px;
              font-weight: 500;
              [data-theme="dark"] & {
                color: #dadada;
              }
            }
          }
          .contact {
            margin-top: 25px;

            ul {
              li {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 10px;
                a {
                  color: #494949;
                  font-size: 15px;
                  font-weight: 400;
                  transition: var(--tj-transition);
                  [data-theme="dark"] & {
                    color: #dadada;
                  }
                  &:hover {
                    color: var(--tj-primary-color);
                  }
                }
                [data-theme="dark"] & {
                  color: #dadada;
                }
              }
            }
          }
        }
        .footer-content {
          .all-desc-footerItem {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin-top: 25px;
            @media (max-width: 576px) {
              margin-top: 20px;
            }
            .desc-path {
              color: #494949;
              font-size: 16px;
              font-weight: 400;
              text-transform: capitalize;
              transition: var(--tj-transition);
              @media (max-width: 992px) {
                font-size: 16px;
              }
              [data-theme="dark"] & {
                color: #dadada;
              }
              &:hover {
                color: var(--tj-primary-color);
              }
            }
          }
        }
        .footer-email {
          .desc {
            margin-top: 20px;
            p {
              color: #494949;
              font-size: 15px;
              font-weight: 500;
              text-transform: capitalize;
              [data-theme="dark"] & {
                color: #dadada;
              }
            }
          }
          .input-div {
            margin: 30px 0;
            form {
              padding: 5px 7px;
              display: flex;
              align-items: center;
              border-radius: 30px;
              border: 1px solid rgba(44, 62, 80, 0.2);
              [data-theme="dark"] & {
                border: 1px solid rgba(255, 255, 255, 0.2);
              }
              input {
                height: 100%;
                background-color: transparent;
                border: none;
                outline: none;
                padding: 10px 0px;
                min-width: 180px;
                color: #080808;
                padding-inline: 10px;
                flex-grow: 1;
                text-align: right;
                [dir="ltr"] & {
                  text-align: left;
                }
                [data-theme="dark"] & {
                  color: #fff !important;
                }
                &::placeholder {
                  color: rgba(128, 128, 128, 0.5);
                  font-size: 13px;
                  font-weight: 400;
                  text-align: right;
                  [dir="ltr"] & {
                    text-align: left;
                  }
                }
                [data-theme="dark"] & {
                  color: #dadada;
                  -webkit-text-fill-color: #fff;
                  opacity: 1;
                }
              }
              .subscribeBtn {
                border: none;
                outline: none;
                border-radius: 30px;
                padding: 11px 30px;
                color: #fff;
                min-width: 100px;
                height: 43px;
                background: var(--tj-linear-gradient-light);
                transition: var(--tj-transition);
                [data-theme="dark"] & {
                  background: var(--tj-linear-gradient-dark);
                }
                &.disabled {
                  opacity: 0.5;
                  transition: var(--tj-transition);
                }
                .spinner-border {
                  height: 16px !important;
                  width: 16px !important;
                }
              }
            }
          }
          .social-media {
            margin-top: 18px;
            display: flex;
            gap: 8px;
            @media (max-width: 1200px) {
              justify-content: center;
            }
            a {
              transition: var(--tj-transition);
              position: relative;
              z-index: 10;
              &:hover {
                transform: translateY(-5px);
              }
            }
          }
          .error {
            margin-top: -10px;
            color: red;
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 13px;
          }
        }
      }

      @media (max-width: 1200px) {
        .footer-logo {
          text-align: center;
        }
      }
      @media (max-width: 992px) {
        .footer-logo {
          text-align: center;
          margin-bottom: 50px;

          .social-media {
            margin-top: 20px;
            justify-content: center;
          }
        }
        .footer-content {
          margin-bottom: 20px;
          .desc-path {
            font-size: 13px;
          }
        }
      }
      @media (max-width: 576px) {
        .footer-logo {
          margin-bottom: 30px;
        }
      }
    }
    .footer-bottom {
      padding: 10px 0;
      background: #233654;
      min-height: 80px;
      border-top: 1px solid transparent;
      position: relative;
      @media (max-width: 576px) {
        min-height: 50px;
      }
      [data-theme="dark"] & {
        border: 1px solid #333;
        background: var(--tj-body-light-color);
      }
      span {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        @media (max-width: 992px) {
          font-size: 16px;
        }
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }

      .emoji {
        position: absolute;
        top: -75px;
        right: 15%;
        z-index: 0;
        img {
          max-width: 100px;
          max-height: 110px;
          width: 100%;
          height: 100%;
          object-fit: contain;
          animation: bounceXs 4s linear infinite;
        }
        @media (max-width: 992px) {
          top: -70px;
          right: 5%;
          img {
            max-width: 90px;
            max-height: 100px;
          }
        }
        @media (max-width: 576px) {
          top: -60px;
          right: 5%;
          img {
            max-width: 70px;
            max-height: 80px;
          }
        }
      }
    }
  }
}
