.modal-consult {
  :global {
    .modal-dialog {
      display: flex;
      align-items: center;
      position: relative;
      width: 100% !important;
      height: 100% !important;
      margin: 0 auto !important;

      .modal-content {
        [data-theme="dark"] & {
          background: #2c2c2c;
        }
        .modal-header {
          .title {
            [data-theme="dark"] & {
              color: #fff;
            }
          }
          .btn-close {
            margin: 0;
            outline: none;
            [data-theme="dark"] & {
              filter: brightness(0) invert(1);
            }
          }
          [data-theme="dark"] & {
            border-bottom-color: #4d4d4d;
          }
        }
        .modal-body {
          padding-bottom: 0;
        }

        .container {
          justify-content: center;

          .first-page {
            margin-top: 20px;
            .first-group {
              margin-bottom: 30px;
              .row {
                row-gap: 10px;
                @media (max-width: 768px) {
                  row-gap: 8px;
                }
              }
              .title {
                color: #233654;
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 30px;
                [data-theme="dark"] & {
                  color: #fff;
                }
                @media (max-width: 768px) {
                  font-size: 20px;
                  margin-bottom: 15px;
                }
              }
              .input-div {
                display: flex;
                flex-direction: column;
                gap: 10px;
                @media (max-width: 768px) {
                  gap: 8px;
                }
                label {
                  color: #233654;
                  font-size: 14px;
                  font-weight: 500;
                  position: relative;
                  // padding: 0 5px;
                  [data-theme="dark"] & {
                    color: #fff;
                  }
                  &::after {
                    content: "*";
                    position: absolute;
                    top: -4px;
                    color: var(--tj-primary-color);
                    width: 15px;
                    text-align: center;
                    font-size: 20px;
                    // [data-theme="dark"] & {
                    //   color: var(--tj-white-color);
                    // }
                  }
                  &.optional {
                    &::after {
                      content: unset;
                    }
                  }
                }
                input {
                  border-radius: 20px;
                  border: 1px solid #e7e8e9;
                  background: #f8f9fc;
                  min-height: 50px;
                  outline: none;
                  padding: 10px 20px;
                  text-align: right;
                  [data-theme="dark"] & {
                    background: #181818ce;
                    border: 1px solid rgb(31, 31, 31);
                    color: #fff;
                    -webkit-text-fill-color: #fff;
                    opacity: 1;
                  }
                  margin-bottom: 10px;
                  @media (max-width: 768px) {
                    padding: 4px 15px;
                    min-height: 45px;
                  }
                  [dir="ltr"] & {
                    text-align: left;
                  }
                }
              }
              .error {
                color: rgb(255, 56, 56);
                margin-top: 5px;
              }
            }
            .btn-div {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}
