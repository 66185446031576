:root {
  --tj-transition: all 0.3s;
  --tj-primary-color: #e74c3c;
  --tj-secondary-color: #fc6b14;
  --tj-background-color: #f6f6f6;
  --tj-text-color: #233654;
  --tj-light-white-color: #d8d8d8;
  --tj-white-color: #fff;
  --tj-black-color: #000;
  --tj-body-color: #141414;
  --tj-body-light-color: #1a1a1a;
  --tj-card-border-color: #424242;
  --tj-linear-gradient-light: linear-gradient(
    90deg,
    #e74c3c -0.02%,
    #fc6b14 99.99%
  );
  --tj-linear-gradient-dark: linear-gradient(
    90deg,
    #a93d32 -0.02%,
    #c86326 99.99%
  );
  --tj-linear-gradient-accordion-rtl: linear-gradient(
    -135deg,
    #fc6b14 0%,
    transparent 30%,
    transparent 70%,
    #fc6b14 100%
  );
  --tj-linear-gradient-accordion-ltr: linear-gradient(
    135deg,
    #fc6b14 0%,
    transparent 30%,
    transparent 70%,
    #fc6b14 100%
  );
}
/* FONTS */
@font-face {
  font-family: "font-light";
  src: url("../src/assets/fonts/Bahij_TheSansArabic-Light.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "font-regular";
  src: url("../src/assets/fonts/Bahij_TheSansArabic-Plain.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "font-semibold";
  src: url("../src/assets/fonts/Bahij_TheSansArabic-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "font-bold";
  src: url("../src/assets/fonts/Bahij_TheSansArabic-Bold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "font-black";
  src: url("../src/assets/fonts/Bahij_TheSansArabic-ExtraBold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  font-family: "font-regular";
  transition: var(--tj-transition);
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: middle;
  background: transparent;
  height: 100%;
  scroll-behavior: smooth;
  text-transform: capitalize;
  font-size: 14px;
  body {
    @extend html;

    background: #f8f9fc;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
    overflow-x: hidden;
    .wrap {
      overflow-x: hidden;
    }

    ul {
      list-style: none;
    }
    a,
    a:hover,
    a:visited,
    a:link {
      text-decoration: none;
      outline: none;
      cursor: pointer;
    }

    *,
    *::before,
    *::after {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: middle;
      background: transparent;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: inherit;
      font-weight: normal;
      line-height: 1.3;
    }

    textarea {
      resize: none;
    }
  }

  &[data-theme="dark"] {
    body {
      background: var(--tj-body-color);
    }
  }

  .modal-backdrop.show {
    opacity: 0.8 !important;
  }
  .modal-content {
    padding: 20px 0;
  }
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
and input::-ms-reveal,
input::-ms-clear {
  display: none;
}
html body {
  -webkit-text-size-adjust: 100%; /* For WebKit-based browsers */
  text-size-adjust: 100%; /* Standard property */
}
th {
  text-align: -webkit-match-parent !important; /* For WebKit browsers */
  text-align: match-parent !important; /* Standard property */
}

.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-big {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transform: translateZ(0);
  position: fixed;
  width: 100%;
  z-index: 999;
  inset: 0;
  transition: var(--tj-transition);
  overflow: hidden;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  &.fired {
    opacity: 1;
    visibility: visible;
    transition: var(--tj-transition);
  }
}

.tooltip {
  padding: 12px 0;
  .tooltip-inner {
    font-weight: 300;
    background: #241a04;
    border-radius: 0;
    padding: 6px 13px;
    border-radius: 8px;
    font-family: "font-regular";
  }

  &[data-popper-placement^="top"] {
    .tooltip-arrow {
      display: none;
      &::before {
        border-top-color: #1a1711;
      }
    }
  }
}

// Constants
.btn-md {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  min-height: 45px;
  min-width: 120px;
  width: fit-content;
  border-radius: 70px;
  transition: var(--tj-transition);
  background: var(--tj-linear-gradient-light);
  [data-theme="dark"] & {
    background: var(--tj-linear-gradient-dark);
  }
  @media (max-width: 992px) {
    border-radius: 60px;
  }
  &:hover {
    color: #fff;
    transform: scale(1.05);
  }
}
.btn-md-outline {
  color: var(--tj-primary-color);
  border: 1px solid var(--tj-primary-color);
  background: #fff;
  [data-theme="dark"] & {
    border: 1px solid #f3e7e6;
    background: #000;
    color: #fff;
  }
  @media (max-width: 992px) {
    border-radius: 60px;
  }
  &:hover {
    color: var(--tj-primary-color);
    transform: unset;
    [data-theme="dark"] & {
      color: #fff;
    }
  }
}
.no-content {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding-block: 30px;
  &.main {
    margin-top: 50px;
  }
  // [data-theme="dark"] & {
  //   color: var(--tj-white-color);
  // }
}

.showSidebar-btn {
  display: none;
  @media (max-width: 1200px) {
    display: flex;
    margin-bottom: 20px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 10px 16px;
      background-color: transparent;
      border-radius: 8px;
      border: 1px solid #444444;
      width: 100%;
      [data-theme="dark"] & {
        color: var(--tj-white-color);
        svg {
          filter: brightness(0) invert(1);
        }
      }
    }
  }
}
.swiper-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  // transition: var(--tj-transition);
  @media (max-width: 992px) {
    width: 50px;
    height: 50px;
  }
  @media (max-width: 576px) {
    width: 45px;
    height: 45px;
  }
  [data-theme="dark"] & {
    background-color: #232323;
    svg {
      fill: var(--tj-white-color);
    }
  }
  [dir="ltr"] & {
    transform: scaleX(-1);
  }
  &::after {
    content: unset;
  }

  &:hover {
    background: var(--tj-linear-gradient-light);
    svg {
      fill: var(--tj-white-color);
    }
    [data-theme="dark"] & {
      background: var(--tj-linear-gradient-dark);
    }
  }
  &.swiper-button-disabled {
    opacity: 0.3;
    pointer-events: none;
    &:hover {
      background: unset;
      svg {
        fill: unset;
      }

      [data-theme="dark"] & {
        background: transparent;
        svg {
          fill: #fff;
        }
      }
    }
  }
}

.overlay-g {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 67%);
  z-index: 10;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}

@keyframes bounceSm {
  0%,
  100% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
}

@keyframes bounceXs {
  0%,
  100% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
}

@keyframes bounceXs2 {
  0%,
  100% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0px);
  }
}
