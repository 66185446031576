$scale: 50px;
$time: 0.3s;
@mixin common($a, $b, $c, $d, $e, $f, $g) {
  display: $a;
  flex-direction: $b;
  justify-content: $c;
  align-items: $d;
  width: $e;
  height: $f;
  background-color: $g;
  transition: all $time ease;
}

.main-head {
  :global {
    position: absolute;
    top: 0;
    // z-index: 10;
    z-index: 50;
    left: 0;
    width: 100%;

    .bottombar {
      padding: 30px 0 10px 0;

      .container {
        .inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 22px;
          gap: 20px;
          border-radius: 130px;
          box-shadow: 0px 68px 53px 0px rgba(0, 0, 0, 0.03),
            0px 13.6px 8.613px 0px rgba(0, 0, 0, 0.01);
          background: #fff;
          [data-theme="dark"] & {
            background: #141414;
          }
          @media (max-width: 576px) {
            padding: 12px 22px;
          }
        }
        .menu-btn {
          display: none;
          padding: 0;
          border: none;
          background-color: transparent;
          [data-theme="dark"] & {
            svg {
              path {
                &:first-child {
                  fill: var(--tj-white-color);
                }
              }
            }
          }
          @media (max-width: 992px) {
            display: flex;
          }
          &.remove-topbar {
            svg {
              path {
                &:first-child {
                  fill: var(--tj-white-color);
                }
              }
            }
          }
        }
        .menu {
          display: flex;

          @media (max-width: 992px) {
            display: none;
          }
          ul {
            display: flex;
            align-items: center;
            gap: 26px;
            .active {
              color: var(--tj-secondary-color);
              [data-theme="dark"] & {
                color: var(--tj-secondary-color);
              }
            }
            li {
              a {
                transition: var(--tj-transition);
                color: #233654;
                font-size: 16px;
                font-weight: 500;
                [data-theme="dark"] & {
                  color: var(--tj-white-color);
                }
                &:hover {
                  color: var(--tj-secondary-color);
                }
              }
            }
            @media (min-width: 992px) and (max-width: 1200px) {
              gap: 18px;
              li {
                a {
                  font-size: 15px;
                  font-weight: 500;
                }
              }
            }
          }
          &.remove-topbar {
            ul {
              .active {
                color: var(--tj-secondary-color);
                [data-theme="dark"] & {
                  color: var(--tj-secondary-color);
                }
              }
              li {
                a {
                  color: var(--tj-white-color);
                  [data-theme="dark"] & {
                    color: var(--tj-white-color);
                  }
                  &:hover {
                    color: var(--tj-secondary-color);
                  }
                }
              }
            }
          }
        }
        .logo {
          a {
            display: flex;
            img {
              object-fit: contain;
              max-width: 172px;
              max-height: 52px;

              @media (min-width: 992px) and (max-width: 1200px) {
                max-width: 150px;
                max-height: 40px;
              }
              @media (max-width: 992px) {
                max-width: 120px;
              }
              @media (max-width: 576px) {
                max-width: 110px;
              }
            }
            .logo-dark {
              display: none;
            }
            .logo-light {
              display: flex;
            }

            [data-theme="dark"] & {
              .logo-dark {
                display: flex;
              }
              .logo-light {
                display: none;
              }
            }
          }
          &.remove-topbar {
            a {
              .logo-dark {
                display: flex;
              }
              .logo-light {
                display: none;
              }
              [data-theme="dark"] & {
                .logo-dark {
                  display: flex;
                }
                .logo-light {
                  display: none;
                }
              }
            }
          }
        }

        .features {
          display: flex;
          align-items: center;
          gap: 20px;
          @media (max-width: 992px) {
            display: none;
          }
          .language {
            font-size: 16px;
            color: #0a0a0a;
            transition: var(--tj-transition);
            [data-theme="dark"] & {
              color: var(--tj-white-color);
            }
            &:hover {
              color: var(--tj-primary-color);
            }
          }

          .login {
            font-size: 16px;
            font-weight: 500;
            color: #233654;

            [data-theme="dark"] & {
              color: var(--tj-white-color);
            }
            &.remove-topbar {
              color: var(--tj-white-color);
            }
          }

          @media (min-width: 992px) and (max-width: 1200px) {
            gap: 15px;
            .login {
              font-size: 15px;
            }
          }
        }
      }
      .openSearch {
        display: none;
        @media (max-width: 992px) {
          display: flex;
        }
        .container {
          padding: 0px;
          padding-bottom: 15px;
          .search-input-div {
            border-radius: 8px;
            background: rgba(4, 4, 20, 0.03);
            padding: 10px;
            width: 100%;
            display: flex;
            [data-theme="dark"] & {
              background: #2c2c2c;
            }
            input {
              background-color: transparent;
              outline: none;
              border: none;

              flex-grow: 1;

              [data-theme="dark"] & {
                color: var(--tj-white-color);
              }
            }
            button {
              background-color: transparent;
              outline: none;
              border: none;
              padding: 0;
              [data-theme="dark"] & {
                filter: brightness(0) invert(1);
              }
            }
          }
        }
      }
    }

    .mobileSidebar.active {
      transform: translateX(0%);
    }
    .mobileSidebar {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      background: var(--tj-white-color);
      height: 100%;
      box-shadow: 3px 0 15px rgba(0, 0, 0, 0.11);
      transition: var(--tj-transition);
      transform: translateX(-150%);
      z-index: 400;
      [data-theme="dark"] & {
        background: #040414;
      }

      .inner {
        overflow: hidden;
        overflow-y: auto;
        max-height: 100%;
        height: 100%;
        padding: 20px 0px;
        .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 25px;
          padding-bottom: 15px;
          border-bottom: 1px solid #dfdfdf;
          [data-theme="dark"] & {
            border-bottom: 1px solid rgba(255, 255, 255, 0.18);
          }
          .logo {
            color: #000;
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
            [data-theme="dark"] & {
              color: #fff;
            }
          }
          .close-btn {
            background-color: transparent;
            border: none;
            [data-theme="dark"] & {
              filter: brightness(0) invert(1);
            }
          }
        }
        .menu-mobileMode {
          padding: 0 25px;
          ul {
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #dfdfdf;
            [data-theme="dark"] & {
              border-bottom: 1px solid rgba(255, 255, 255, 0.18);
            }
            li {
              padding: 10px 0;
              a {
                transition: var(--tj-transition);
                color: #000;
                font-size: 14px;
                font-weight: 500;
                text-transform: capitalize;
                [data-theme="dark"] & {
                  color: #f4f5f7;
                }
                &:hover {
                  color: var(--tj-primary-color);
                }
                &.active {
                  color: var(--tj-primary-color);
                }
              }
            }
          }
        }
        .favoriteLink {
          padding: 0 25px;
          display: flex;
          .inner {
            width: 100%;
            border-bottom: 1px solid #dfdfdf;
            [data-theme="dark"] & {
              border-bottom: 1px solid rgba(255, 255, 255, 0.18);
            }
          }
          .booking {
            transition: var(--tj-transition);
            color: #000;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
            background: transparent;
            border: none;
            outline: none;

            [data-theme="dark"] & {
              color: #f4f5f7;
              svg {
                filter: brightness(0) invert(1);
              }
            }
            &:hover {
              color: var(--tj-primary-color);
            }
            &.active {
              color: var(--tj-primary-color);
            }
          }
        }
        .languages {
          padding: 0 25px;
          .inner {
            border-bottom: 1px solid #dfdfdf;
            [data-theme="dark"] & {
              border-bottom: 1px solid rgba(255, 255, 255, 0.18);
            }
            h3 {
              display: flex;
              align-items: center;
              gap: 10px;
              color: #000;
              font-size: 14px;
              font-weight: 500;
              text-transform: capitalize;
              padding-bottom: 20px;
              [data-theme="dark"] & {
                color: #f4f5f7;
                svg {
                  filter: brightness(0) invert(1);
                }
              }
            }
            .choices {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              column-gap: 35px;
              row-gap: 20px;
              .language {
                color: #000;
                font-size: 14px;
                font-weight: 500;
                text-transform: capitalize;
                transition: var(--tj-transition);
                background-color: transparent;
                border: none;
                padding: 0;
                [data-theme="dark"] & {
                  color: #f4f5f7;
                }
                &:hover {
                  color: var(--tj-primary-color);
                }
              }
            }
          }
        }
      }
    }

    // Constants
    .btn-md {
      @media (min-width: 992px) and (max-width: 1200px) {
        padding: 8px 20px;
        font-size: 15px;
        min-height: 45px;
        min-width: 120px;
      }
    }
    .btnToggle {
      align-self: center;
      margin: 15px 0;

      &.mobile {
        padding: 0 25px;
      }
      &.desktop {
        @media (max-width: 992px) {
          display: none;
        }
      }

      input[type="checkbox"] {
        display: none;
      }
    }
    .box {
      @include common(flex, column, center, null, $scale, $scale/2, black);
      position: relative;
      border-radius: $scale/4;
      cursor: pointer;
      [data-theme="dark"] & {
        background-color: #fff;
      }

      //   Style rules for the roller .ball class

      .ball {
        @include common(null, null, null, null, $scale/2, $scale/2, grey);
        position: absolute;
        border-radius: 50%;
        border: 3px solid black;
        background-color: #fff;
        [data-theme="dark"] & {
          transform: translatex(-100%);
          background-color: #f3c626;
          border: 3px solid #fff;
        }
        [data-theme="dark"] [dir="ltr"] & {
          transform: translatex(100%);
        }
      }

      //   Style rules for the .scenary(.sun, .moon) class

      .scenary {
        @include common(flex, row, space-between, null, null, null, null);
        padding: 0px 10px 0px 10px;
        svg {
          width: $scale/3;
        }
        .sun,
        .moon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
